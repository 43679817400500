<template>
  <div>
    <div class="navWrapper">
      <img class="backButton" src="@/assets/back02.png" height="40" @click="goBack"/>
      <div class="title">通讯录</div>
    </div>
    <div class="container">
      <div class="picWrapper">
        <el-carousel
          ref="carousel"
          :autoplay=autoplay
          type="card"
          height="55vh"
          arrow="never"
          :initial-index="activeIndex"
          @change="saveIndex"
          style="overflow-x: hidden"
        >
          <my-carousel v-for="(item, i) in dataList" :key="i">
              <img class="imgItem" v-lazyload :data-src='`images/${item}`'
                        alt="植物资料卡片" @click="peekPlant(i+1)" loading="lazy"/>
          </my-carousel>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import MyCarousel from '@/components/MyCarousel'

export default {
  name: 'AddressBook',
  components: { MyCarousel },
  data () {
    return {
      activeIndex: 0,
      autoplay: false,
      dataList: ['01.jpg', '02.jpg', '03.jpg', '04.jpg', '05.jpg', '06.jpg', '07.jpg', '08.jpg', '09.jpg', '10.jpg',
        '11.jpg', '12.jpg', '13.jpg', '14.jpg']
    }
  },
  mounted () {
    this.slideBanner()
    window.scrollBy(0, 0)
    this.activeIndex = localStorage.getItem('active') ? Number(localStorage.getItem('active')) : 0
  },
  directives: {
    lazyload: {
      bind (el) {
        const observer = new IntersectionObserver(entries => {
          console.log(entries)
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const img = entry.target
              img.src = img.getAttribute('data-src')
              observer.unobserve(img)
            }
          })
        })
        observer.observe(el)
      }
    }
  },
  methods: {
    saveIndex (index) {
      localStorage.setItem('active', index)
    },
    goBack () {
      this.$router.push({ name: 'welcome' })
    },
    peekPlant (_id) {
      localStorage.setItem('active', _id - 1)
      this.$router.push({
        name: 'plantDetail',
        params: { id: _id }
      })
    },

    // /*轮播手滑切换*/
    startAuto () {
      // eslint-disable-next-line eqeqeq
      if (!this.autoplay) {
        this.autoplay = true
      }
    },
    stopAuto () {
      if (this.autoplay) {
        this.autoplay = false
      }
    },
    slideBanner () {
      const that = this
      // 选中item的盒子
      const box = document.querySelector('.el-carousel__container')
      // 手指起点X坐标
      let startPoint = 0
      // 手指滑动重点X坐标
      let stopPoint = 0

      // 重置坐标
      const resetPoint = function () {
        startPoint = 0
        stopPoint = 0
      }

      // 手指按下
      box.addEventListener('touchstart', function (e) {
        // 手指按下的时候停止自动轮播
        that.stopAuto()
        // 手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX
      })
      // 手指滑动
      // box.addEventListener('touchmove', debounce(function (e) {
      //   // 手指滑动后终点位置X的坐标
      //   console.log('touchmove')
      //   console.log(e)
      //   stopPoint = e.changedTouches[0].pageX
      // }, 1000)
      // )
      // 当手指抬起的时候，判断图片滚动离左右的距离
      box.addEventListener('touchend', function (e) {
        stopPoint = e.changedTouches[0].pageX
        // eslint-disable-next-line eqeqeq
        if (stopPoint === 0 || startPoint - stopPoint === 0) {
          resetPoint()
          return
        }
        if (startPoint - stopPoint > 0) {
          resetPoint()
          // this.$refs.carousel.next()
          that.$refs.carousel.next()
          return
        }
        if (startPoint - stopPoint < 0) {
          resetPoint()
          that.$refs.carousel.prev()
        }
      })
    }
  }
}
</script>

<style scoped>
.container {
  height: 92vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navWrapper {
  display: flex;
  position: relative;
  height: 8vh;
  align-items: center;
  line-height: 60px;
  background-color: rgb(247, 246, 237);
  box-shadow: #2f2f27 0 0 10px;
}

.backButton {
  position: absolute;
  left: 4vw;
  cursor: pointer;
}

.title {
  position: relative;
  margin: 0 auto;
  font-size: 26px;
  font-weight: bold;
  color: #545536;
}

.picWrapper {
  width: 90vw;
  overflow: hidden;
}

.imgItem {
  user-select: none;
  /*margin-right: auto;*/
  margin-left: 0;
  width: 18vw;
  /*min-width: 100%;*/
  display: inline-block;
  /*height: 100%;*/
  min-height: 45vh;
  border-radius: 4em;
  border: #aaaa98 solid 1px;
}

.el-carousel__item {
  width: 20vw;
}

</style>
