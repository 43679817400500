export function imgPathFilter (pList, dir) {
  const tempList = []
  pList.forEach(p => {
    tempList.push('/images/' + dir + '/' + p)
  })
  return tempList
}

export function imgPathFilter2 (pList, dir) {
  const tempList = []
  pList.forEach(p => {
    tempList.push({ src: '/images/' + dir + '/' + p })
  })
  return tempList
}
