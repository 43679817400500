<template>
  <div class="container">
    <div class="mainPart">
      <p style="font-size: 30px">正在呼叫</p>
      <div class="ellipsis-container">
        <div class="ellipsis"></div>
      </div>
      <p style="font-size: 3em;margin-bottom: 20px;">{{ plantName }}</p>
      <div>
        <div class="circle-container">
          <div class="circle circle-4"></div>
          <div class="circle circle-3"></div>
          <div class="circle circle-2"></div>
          <div class="circle circle-1">
            <img :src="`/images/c${idStr}.jpg`"/>
          </div>
          <div class="infoCard">
            <transition name="el-fade-in-linear">
              <el-card v-show="show" class="transition-box">
                <p>对不起T_T</p>
                <p>您拨打的植物正在接待小蜜蜂</p>
                <p>请稍后再拨~</p>
              </el-card>
            </transition>
          </div>
        </div>
      </div>
      <div>
        <i class="el-icon-error" style="color: #fa5152; font-size: 50px" @click="goBack"></i>
        <p style="font-size: 24px">挂断</p>
      </div>
    </div>
  </div>
</template>

<script>
import { plantGet } from '@/api/api'

export default {
  name: 'CallView',
  data () {
    return {
      id: 0,
      idStr: '',
      plantName: ' ',
      show: false,
      checkRecordTimer: null,
      returnTimer: null,
      audioElement: null,
      speech: null
    }
  },
  beforeDestroy () {
    clearTimeout(this.checkRecordTimer)
    clearTimeout(this.returnTimer)
    this.stopTelephoneRing()
    // this.stopSpeech()
  },
  mounted () {
    this.id = this.$route.params.id
    if (this.id < 10) {
      this.idStr = '0' + this.id
    } else {
      this.idStr = '' + this.id
    }
    this.getPlant()
    this.startTelephoneRing('/du.m4a')
    this.show = false
    this.checkRecordTimer = setTimeout(() => {
      this.handleRecord()
    }, 10000)
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    getPlant () {
      plantGet(this.id).then(res => {
        if (res.code === 200) {
          this.plantName = res.data.plant.name
        } else {
          this.$message.error({
            message: res.msg,
            duration: 10000
          })
          setTimeout(() => {
            this.$router.push({ name: 'addressBook' })
          }, 10000)
        }
      })
    },
    handleCall () {
      this.$router.push({
        name: 'call',
        params: { id: this.id }
      })
    },
    // handleRecord () {
    //   const lastId = parseInt(localStorage.getItem('lastId'))
    //   const nowId = parseInt(this.id)
    //   const n = parseInt(localStorage.getItem('times'))
    //   if (nowId === lastId && n >= 3) {
    //     this.stopTelephoneRing()
    //     localStorage.setItem('times', '0')
    //     localStorage.setItem('lastId', '')
    //     this.show = true
    //     this.resetSpeech('对不起, 您拨打的电话正在通话中, 请稍后再拨')
    //     this.returnTimer = setTimeout(() => {
    //       this.$router.push({
    //         name: 'plantDetail',
    //         params: { id: this.id }
    //       })
    //     }, 10000)
    //   } else {
    //     this.$router.push({
    //       name: 'dialog',
    //       params: { id: this.id }
    //     })
    //   }
    // },
    handleRecord () {
      const n = parseInt(localStorage.getItem('times'))
      if (n >= 3) {
        this.stopTelephoneRing()
        localStorage.setItem('times', '0')
        this.show = true
        this.startTelephoneRing('/unConnected.mp3')
        // this.resetSpeech('对不起, 您拨打的电话正在通话中, 请稍后再拨')
        this.returnTimer = setTimeout(() => {
          this.$router.push({
            name: 'plantDetail',
            params: { id: this.id }
          })
        }, 10000)
      } else {
        this.$router.push({
          name: 'dialog',
          params: { id: this.id }
        })
      }
    },
    startTelephoneRing (name) {
      this.stopTelephoneRing()
      this.audioElement = new Audio(name)
      this.audioElement.play()
    },
    stopTelephoneRing () {
      if (this.audioElement) {
        this.audioElement.pause()
        this.audioElement.currentTime = 0
      }
    }
    // stopSpeech () {
    //   if (this.speech) {
    //     window.speechSynthesis.cancel()
    //     this.speech = null
    //   }
    // },
    // resetSpeech (text) {
    //   this.stopSpeech()
    //   this.speech = new SpeechSynthesisUtterance(text)
    //   this.speech.rate = 0.8
    //   window.speechSynthesis.speak(this.speech)
    // }
  }
}
</script>

<style scoped>
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainPart {
  width: 100vw;
  margin-top: -5vh;
}

p {
  font-weight: bold;
  color: #545536;
  margin-bottom: 0;
  margin-top: 0.4em;
  /*line-height: 1.5em;*/
}

.circle-container {
  margin: 2px auto;
  position: relative;
  width: 320px; /* 设置容器的宽度 */
  height: 350px; /* 设置容器的高度 */
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.circle-4 {
  width: 320px;
  height: 320px;
  border: #e8e8dd solid 8px;
}

.circle-3 {
  width: 250px;
  height: 250px;
  border: #c5c5b8 solid 7px;
}

.circle-2 {
  width: 195px;
  height: 195px;
  border: #bbbba7 solid 5px;
}

.circle-1 {
  width: 160px;
  height: 160px;
  background-color: transparent;
  overflow: hidden;
  border: #8a8f6e solid 4px;
}

.circle-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.infoCard {
  z-index: 10;
  position: relative;
  width: 320px;
  margin: 0 auto;
  top: 35%
}

.infoCard p {
  font-size: larger;
}

.ellipsis-container {
  position: relative;
  margin: 0 auto;
  width: 200px; /* 调整容器的宽度 */
  height: 20px;
  /*overflow: hidden;*/
  padding: 0;
}

.ellipsis {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ellipsis::before,
.ellipsis::after {
  content: '...';
  line-height:20px;
  font-size: 40px;
  font-weight: bold;
  color: #545536;
  opacity: 0;
  animation: ellipsisAnimation 1.5s infinite;
}

.ellipsis::before {
  animation-delay: 0.2s; /* 调整第一个点的延迟显示时间 */
}

.ellipsis::after {
  animation-delay: 0.4s; /* 调整第二个点的延迟显示时间 */
}

@keyframes ellipsisAnimation {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>
