<template>
  <div>
    <div class="navWrapper">
      <img class="backButton" src="@/assets/back02.png" height="40" @click="goBack"/>
      <div class="title">照片墙</div>
    </div>
    <div class="stream">
      <div class="masonry">
        <el-image
          class="itemImg item"
          :src="p"
          lazy
          v-for="(p,i) in picList" :key="dir+i"
          :preview-src-list="picList"
        ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
import { filesGet } from '@/api/api'
import { imgPathFilter } from '@/utils/filter'

export default {
  name: 'pictureWall',
  data () {
    return {
      previewImage: '1',
      previewVisible: false,
      dir: '',
      picNum: 0,
      showViewer: false,
      viewPhotoList: null,
      plantList: [
        {
          name: '射干',
          id: '01'
        },
        {
          name: '钝叶鸡蛋花',
          id: '02'
        },
        {
          name: '叶子花',
          id: '03'
        }, {
          name: '绒面肖竹芋',
          id: '04'
        },
        {
          name: '油棕',
          id: '05'
        },
        {
          name: '榕树',
          id: '06'
        }, {
          name: '琴叶榕',
          id: '07'
        },
        {
          name: '狐尾天门冬',
          id: '08'
        },
        {
          name: '蒲葵',
          id: '09'
        }, {
          name: '芭蕉',
          id: '10'
        },
        {
          name: '芒萁',
          id: '11'
        },
        {
          name: '鹅掌柴',
          id: '12'
        },
        {
          name: '美丽异木棉',
          id: '13'
        },
        {
          name: '落羽杉',
          id: '14'
        }

      ],
      picList: [],
      id: ''
    }
  },
  activated () {
    // this.handlePickPlant('01')
    this.init()
  },
  methods: {
    init () {
      this.id = this.$route.params.id
      if (this.id < 10) {
        const idStr = '0' + this.id
        this.handlePickPlant(idStr)
      } else {
        const idStr = '' + this.id
        this.handlePickPlant(idStr)
      }
    },
    goBack () {
      this.$router.push({
        name: 'plantDetail',
        id: this.id
      })
    },
    getPictures (id) {
      filesGet(id).then(res => {
        this.picList = imgPathFilter(res.data.files, id)
        this.viewPhotoList = imgPathFilter(res.data.files, id)
      })
    },
    handlePickPlant (id) {
      this.dir = id
      this.getPictures(id)
    }
  }
}
</script>

<style scoped>
.navWrapper {
  top: 0;
  display: flex;
  position: fixed;
  width: 100vw;
  z-index: 10;
  height: 8vh;
  align-items: center;
  line-height: 60px;
  background-color: rgb(247, 246, 237);
  box-shadow: #2f2f27 0 0 10px;
}

.backButton {
  position: absolute;
  /*top: 10px;*/
  left: 4vw;
  cursor: pointer;
}

.title {
  position: relative;
  margin: 0 auto;
  font-size: 26px;
  font-weight: bold;
  color: #545536;
}

.buttonWrapper {
  margin: 10vh auto 5vh auto;
  width: 90%;
  text-align: left;
}

.navButton {
  margin-top: 10px;
  color: #545536;
  background-color: white;
  border: solid #545536 1px;
  box-shadow: #b9b8b2 5px 5px 10px;
}

.navButton:hover {
  background-color: #545536;
  color: white;
  border: solid #545536 1px;
}

.selected, .navButton:focus {
  background-color: #545536;
  color: white;
  border: solid #545536 1px;
  box-shadow: #b9b8b2 5px 5px 10px;
}

.stream {
  margin: 10vh auto;
  width: 90%;
}

.masonry {
  column-gap: 10px;
  column-count: 4;
}

.item {
  /*position: relative;*/
  margin-bottom: 10px;
  box-sizing: border-box;
  counter-increment: item-counter;
  border-radius: 10px;
  overflow: hidden;
}

.itemImg {
  display: block;
  width: 100%;
  height: auto;
  cursor: zoom-in;
}

.item:nth-child(4n+1) {
  background-color: #cdd2fa;
}

.item:nth-child(4n+2) {
  background-color: #fcc9d4;
}

.item:nth-child(4n+3) {
  background-color: #def5e8;
}

.item:nth-child(4n+4) {
  background-color: #f1edc2;
}

</style>
