<template>
  <div
    v-show="ready"
    class="el-carousel__item"
    :class="{
      'is-active': active,
      'el-carousel__item--card': $parent.type === 'card',
      'is-in-stage': inStage,
      specialIndex: specialIndex,
      'is-hover': hover,
      'is-animating': animating,
    }"
    @click="handleItemClick"
    :style="itemStyle"
  >
    <div
      v-if="$parent.type === 'card'"
      v-show="!active"
      class="el-carousel__mask"
    ></div>
    <slot></slot>
  </div>
</template>

<script>
import { autoprefixer } from 'element-ui/src/utils/util'

const CARD_SCALE = 0.83
export default {
  name: 'ElCarouselItem',
  props: {
    name: String,
    label: {
      type: [String, Number],
      default: ''
    }
  },

  data () {
    return {
      hover: false,
      translate: 0,
      scale: 1,
      active: false,
      ready: false,
      inStage: false,
      specialIndex: false,
      animating: false
    }
  },
  created () {
    this.$parent && this.$parent.updateItems()
  },

  destroyed () {
    this.$parent && this.$parent.updateItems()
  },

  methods: {
    processIndex (index, activeIndex, length) {
      // if (activeIndex === 0) {
      //   if (index === length - 1) {
      //     return -1
      //   } else if (index === length - 2) {
      //     return -2
      //   }
      // } else if (activeIndex === 1 && index === length - 1) {
      //   return -1
      // } else if (activeIndex === length - 1) {
      //   if (index === 0) {
      //     return length
      //   } else if (index === 1) {
      //     return length + 1
      //   }
      // } else if (activeIndex === length - 2 && index === 0) {
      //   return length
      // } else if (index < activeIndex - 2 && activeIndex - index >= length / 2) {
      //   return activeIndex - 3
      // } else if (index > activeIndex + 2 && index - activeIndex >= length / 2) {
      //   return activeIndex + 3
      // }
      return index
    },

    calcCardTranslate (index, activeIndex) {
      const parentWidth = this.$parent.$el.offsetWidth
      if (this.inStage) {
        return parentWidth * (index - activeIndex + 2) / 5
      } else if (index < activeIndex) {
        return -(1 + CARD_SCALE) * parentWidth / 5
      } else {
        return (6 + CARD_SCALE) * parentWidth / 5
      }
    },
    // const parentWidth = this.$parent.$el.offsetWidth
    // if (this.inStage) {
    //   return parentWidth * ((2 - CARD_SCALE) * (index - activeIndex) + 1) / 4
    // } else if (index < activeIndex) {
    //   return -(1 + CARD_SCALE) * parentWidth / 4
    // } else {
    //   return (3 + CARD_SCALE) * parentWidth / 4
    // }
    // },
    calcTranslate (index, activeIndex, isVertical) {
      const distance = this.$parent.$el[isVertical ? 'offsetHeight' : 'offsetWidth']
      return distance * (index - activeIndex)
    },

    translateItem (index, activeIndex, oldIndex) {
      const parentType = this.$parent.type
      const parentDirection = this.parentDirection
      const length = this.$parent.items.length
      if (parentType !== 'card' && oldIndex !== undefined) {
        this.animating = index === activeIndex || index === oldIndex
      }
      if (index !== activeIndex && length > 2 && this.$parent.loop) {
        index = this.processIndex(index, activeIndex, length)
      }
      if (parentType === 'card') {
        if (parentDirection === 'vertical') {
          console.warn('[Element Warn][Carousel]vertical direction is not supported in card mode')
        }
        this.inStage = Math.round(Math.abs(index - activeIndex)) <= 2
        this.active = index === activeIndex
        this.translate = this.calcCardTranslate(index, activeIndex)
        this.scale = this.active ? 1 : CARD_SCALE
      } else {
        this.active = index === activeIndex
        const isVertical = parentDirection === 'vertical'
        this.translate = this.calcTranslate(index, activeIndex, isVertical)
        this.scale = 1
      }
      this.ready = true
    },
    // processIndex (index, activeIndex, length) {
    //   if (activeIndex === 0) {
    //     if (index === length - 1) {
    //       return -1
    //     } else if (index === length - 2) {
    //       return -2
    //     }
    //   } else if (activeIndex === 1) {
    //     if (index === 0) {
    //       return -1
    //     } else if (index === length - 1) {
    //       return -2
    //     }
    //   }

    // else if (activeIndex === length - 1 && index === 0) {
    //   return length
    // } else if (index < activeIndex - 1 && activeIndex - index >= length / 2) {
    //   return length + 1
    // } else if (index > activeIndex + 1 && index - activeIndex >= length / 2) {
    //   return -3
    // }
    // return index
    // console.log('activeIndex', activeIndex, index)
    // console.log('index', index)
    // if (activeIndex === 0) {
    //   return index === 1 ? 1 : index === 2 ? 2 : index === 3 ? -2 : index === 4 ? -1 : 0
    // }
    // if (activeIndex === 1) {
    //   return index === 2 ? 1 : index === 3 ? 2 : index === 4 ? -2 : index === 0 ? -1 : 0
    // }
    // if (activeIndex === 2) {
    //   return index === 3 ? 1 : index === 4 ? 2 : index === 0 ? -2 : index === 1 ? -1 : 0
    // }
    // if (activeIndex === 3) {
    //   return index === 4 ? 1 : index === 0 ? 2 : index === 1 ? -2 : index === 2 ? -1 : 0
    // }
    // if (activeIndex === 4) {
    //   return index === 0 ? 1 : index === 1 ? 2 : index === 2 ? -2 : index === 3 ? -1 : 0
    // }
    // },

    // calcCardTranslate (index) {
    //   return index * 180 + 320
    // },
    //
    // calcTranslate (index, activeIndex, isVertical) {
    //   const distance = this.$parent.$el[isVertical ? 'offsetHeight' : 'offsetWidth']
    //   return distance * (index - activeIndex)
    // },
    //
    // translateItem (index, activeIndex, oldIndex) {
    //   const parentType = this.$parent.type
    //   const parentDirection = this.parentDirection
    //   const length = this.$parent.items.length
    //   if (parentType !== 'card' && oldIndex !== undefined) {
    //     this.animating = index === activeIndex || index === oldIndex
    //   }
    //   console.log('active：' + activeIndex)
    //   console.log('old' + index)
    //   index = this.processIndex(index, activeIndex, length)
    //   console.log('new' + index)
    //   if (parentType === 'card') {
    //     if (parentDirection === 'vertical') {
    //       console.warn('[Element Warn][Carousel]vertical direction is not supported in card mode')
    //     }
    //     this.inStage = Math.round(Math.abs(index)) <= 2
    //     this.specialIndex = Math.round(Math.abs(index)) >= 3
    //     this.active = index === 0
    //     this.translate = this.calcCardTranslate(index, activeIndex)
    //     this.scale = Math.abs(index) === 0 ? 1 : Math.abs(index) === 1 ? 0.9 : Math.abs(index) === 2 ? 0.76 : 0.62
    //   } else {
    //     this.active = index === activeIndex
    //     const isVertical = parentDirection === 'vertical'
    //     this.translate = this.calcTranslate(index, activeIndex, isVertical)
    //   }
    //   this.ready = true
    // },

    handleItemClick () {
      const parent = this.$parent
      if (parent && parent.type === 'card') {
        const index = parent.items.indexOf(this)
        parent.setActiveItem(index)
      }
    }
  },

  computed: {
    parentDirection () {
      return this.$parent.direction
    },

    itemStyle () {
      const translateType = this.parentDirection === 'vertical' ? 'translateY' : 'translateX'
      const value = `${translateType}(${this.translate}px) scale(${this.scale})`
      // console.log('转换类型', translateType)
      // console.log('偏移', this.translate)
      // console.log('大小', this.scale)
      const style = {
        transform: value
      }
      return autoprefixer(style)
    }
  }
}
</script>
<style scoped>
.el-carousel__arrow--left {
  left: -14px !important;
}

.el-carousel__arrow--right {
  right: -18px !important;
}

.el-carousel__item {
  width: 300px;
  cursor: pointer;
  z-index: 1;
}

.el-carousel__item--card.is-in-stage {
  z-index: 2;
}

.el-carousel__item--card.is-active {
  z-index: 3;
}

.specialIndex {
  z-index: 0;
}
</style>
