<template>
  <div class="container">
    <div class="backPart" @click="goBack()">
      <img src="@/assets/back.png" width="60"/>
    </div>
    <div class="leftPart">
      <el-carousel
        ref="carousel"
        :autoplay=autoplay
        height="64vh"
        arrow="never"
        indicator-position="outside">
<!--        <el-carousel-item >-->
<!--          <img :src="`/images/detail/${idStr}.jpg`" class="picItem"/>-->
<!--        </el-carousel-item>-->
        <el-carousel-item v-for="item in 3" :key="item">
          <img :src="`/images/${idStr}-${item}.jpg`" class="picItem"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="rightPart">
      <p class="name">{{ plant.name }}</p>
      <p class="infoItem">拉丁学名 : <span class="latin-font">{{ plant.latinName }}</span></p>
      <p class="infoItem">科 : {{ plant.family }}</p>
      <p class="infoItem">属 : {{ plant.genus }}</p>
      <p class="infoItem">关键词 : {{ plant.keyword }}</p>
      <p class="infoItem">习性 : {{ plant.habit }}</p>
      <p class="infoItem">出生地 : {{ plant.birthPlace }}</p>
      <el-button round class="callButton" @click="gotoNameId('call')"> 与我通话</el-button>
      <el-button round class="picButton" icon="el-icon-picture-outline"  @click="gotoNameId('pictureWall')">照片墙
      </el-button>
    </div>
  </div>
</template>

<script>
import { plantGet } from '@/api/api'

export default {
  name: 'PlantDetail',
  data () {
    return {
      id: 0,
      idStr: '',
      plant: {},
      autoplay: false
    }
  },
  activated () {
    this.init()
  },
  mounted () {
    this.init()
    this.slideBanner()
  },
  methods: {
    init () {
      this.id = this.$route.params.id
      if (this.id < 10) {
        this.idStr = '0' + this.id
      } else {
        this.idStr = '' + this.id
      }
      this.getPlant()
    },
    gotoNameId (n) {
      this.$router.push({
        name: n,
        id: this.id
      })
    },
    getPlant () {
      plantGet(this.id).then(res => {
        if (res.code === 200) {
          this.plant = res.data.plant
        } else {
          this.$message.error({
            message: res.msg,
            duration: 10000
          })
          setTimeout(() => {
            this.$router.push({ name: 'addressBook' })
          }, 10000)
        }
      })
    },
    goBack () {
      this.$router.push({ name: 'addressBook' })
    },

    // /*轮播手滑切换*/
    startAuto () {
      // eslint-disable-next-line eqeqeq
      if (!this.autoplay) {
        this.autoplay = true
      }
    },
    stopAuto () {
      if (this.autoplay) {
        this.autoplay = false
      }
    },
    slideBanner () {
      const that = this
      // 选中item的盒子
      const box = document.querySelector('.el-carousel__container')
      // 手指起点X坐标
      let startPoint = 0
      // 手指滑动重点X坐标
      let stopPoint = 0

      // 重置坐标
      const resetPoint = function () {
        startPoint = 0
        stopPoint = 0
      }

      // 手指按下
      box.addEventListener('touchstart', function (e) {
        // 手指按下的时候停止自动轮播
        that.stopAuto()
        // 手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX
      })
      // 手指滑动
      box.addEventListener('touchmove', function (e) {
        // 手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX
      })
      // 当手指抬起的时候，判断图片滚动离左右的距离
      box.addEventListener('touchend', function (e) {
        if (stopPoint === 0 || startPoint - stopPoint === 0) {
          resetPoint()
        }
        if (startPoint - stopPoint > 0) {
          resetPoint()
          that.$refs.carousel.next()
          return
        }
        if (startPoint - stopPoint < 0) {
          resetPoint()
          that.$refs.carousel.prev()
        }
      })
    }

  }
}
</script>

<style scoped>
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightPart, .backPart, .leftPart {
  margin-top: auto;
  margin-bottom: auto;
}

.backPart {
  margin-left: 5vw;
  cursor: pointer;
}

.leftPart {
  width: 400px;
  height: 62vh;
}

.picItem {
  width: auto;
  max-height: 62vh;
  border-radius: 2em;
  overflow: hidden;
  box-shadow: #2f2f27 0 2px 5px;
  /*margin: 10px;*/
}

.rightPart {
  width: 40vw;
  margin-right: 5vw;
  text-align: left;
  line-height: 1.5em;
  color: #545536;
  font-weight: bold;
}

.rightPart .name {
  font-size: 3em;
  margin-top: 0;
}

.rightPart p {
  font-size: 1.2em;
  line-height: 1.2em;
}

.picButton {
  border-color: #545536 !important;
  color: #545536 !important;
  font-size: 16px;
}

.picButton:hover {
  background-color: #545536 !important;
  color: #fff !important;
}

.callButton {
  background: #545536 !important;
  border-color: #545536 !important;
  color: #fff !important;
  width: 10em;
  font-size: 18px;
  letter-spacing: 0.5em;
  margin-top: 30px;
}

.callButton:hover {
  background: #fff !important;
  border-color: #545536 !important;
  color: #545536 !important;
  opacity: 0.8;
}

.latin-font {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-style: italic;
}
</style>
