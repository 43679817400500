<template>
  <div class="container">
    <div class="leftPart">
      <p style="font-size: xx-large">正在通话</p>
      <p style="font-size: x-large">{{ formatTime }}</p>
      <p style="font-size: 3em; margin-top:0">{{ plant.name }}</p>
      <div class="picCard">
        <div class="picWrapper">
          <img :src="`/images/${idStr}-1.jpg`"/>
          <div class="answerWrapper">
            <transition name="el-fade-in-linear">
              <div v-show="answerStr" class="transition-box answerCard">
                <p class="text-justify">{{ answerStr }}</p>
              </div>
            </transition>
          </div>
        </div>

      </div>
      <div>
        <i class="el-icon-error" style="color: #fa5152; font-size: 50px;cursor: pointer" @click="goBack"></i>
        <p style="font-size: 24px">挂断</p>
      </div>
    </div>
    <div class="rightPart">
      <div class="questionWrapper">
        <div v-for="q in questionList" :key="q.id" :class="['questionItem', {'selected':q.order===selectedOrder}]"
             @click="getAnswer(q.order)">
          <p>{{ q.content }}</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { answerGet, plantGet, questionsGet, audioGet } from '@/api/api'

export default {
  name: 'CallView',
  data () {
    return {
      id: 0,
      idStr: '',
      plant: {},
      questionList: [],
      show: true,
      answer: null,
      answerStr: '',
      selectedOrder: -1,
      recordTimer: null,
      // speech: null,
      audioElement: null,

      timer: null,
      startTime: null,
      elapsedTime: 0,
      isRunning: false
    }
  },
  mounted () {
    this.id = this.$route.params.id
    if (this.id < 10) {
      this.idStr = '0' + this.id
    } else {
      this.idStr = '' + this.id
    }
    this.getPlant()
    this.getQuestion()
    this.startTimer()
    this.handleRecord()
    // this.resetSpeech()
  },
  beforeDestroyed () {
    this.stopTimer()
    this.stopSpeech()
  },
  computed: {
    formatTime () {
      const minutes = Math.floor(this.elapsedTime / 60)
      const seconds = this.elapsedTime % 60
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }
  },
  methods: {
    // handleRecord () {
    //   localStorage.setItem('lastId', this.id)
    //   if (isNaN(parseInt(localStorage.getItem('times')))) {
    //     localStorage.setItem('times', '1')
    //   } else {
    //     const n = parseInt(localStorage.getItem('times')) + 1
    //     localStorage.setItem('times', n.toString())
    //   }
    // },
    handleRecord () {
      if (isNaN(parseInt(localStorage.getItem('times')))) {
        localStorage.setItem('times', '1')
      } else {
        const n = parseInt(localStorage.getItem('times')) + 1
        localStorage.setItem('times', n.toString())
      }
    },
    goBack () {
      this.$router.push({
        name: 'plantDetail',
        params: { id: this.id }
      })
    },
    getPlant () {
      plantGet(this.id).then(res => {
        if (res.code === 200) {
          this.plant = res.data.plant
        } else {
          this.$message.error({
            message: res.msg,
            duration: 10000
          })
          setTimeout(() => {
            this.$router.push({ name: 'addressBook' })
          }, 10000)
        }
      })
    },
    getQuestion () {
      questionsGet(this.id).then(res => {
        if (res.code === 200) {
          this.questionList = res.data.questions
        } else {
          this.$message.error({
            message: res.msg,
            duration: 10000
          })
          setTimeout(() => {
            this.$router.push({
              name: 'plantDetail',
              params: { id: this.id }
            })
          }, 10000)
        }
      })
    },
    getAnswer (order) {
      if (order === this.selectedOrder) {
        return
      }
      answerGet(this.id, order).then(res => {
        if (res.code === 200) {
          this.answer = res.data.answer
          if (this.answer.content.includes('#flag#')) {
            this.answerStr = this.answer.content.split('#flag#')[0]
            setTimeout(() => {
              this.answerStr = this.answer.content.split('#flag#')[1]
            }, 8000)
          } else {
            this.answerStr = this.answer.content
          }
          this.resetSpeech(this.answer.id)
          this.selectedOrder = order
          this.resetRecordTimer()
        } else {
          this.$message.error({
            message: res.msg,
            duration: 10000
          })
          setTimeout(() => {
            this.$router.push({
              name: 'plantDetail',
              params: { id: this.id }
            })
          }, 10000)
        }
      })
    },

    handleCall () {
      this.$router.push({
        name: 'call',
        params: { id: this.id }
      })
    },

    resetRecordTimer () {
      clearTimeout(this.recordTimer)
      const n = Math.round(this.answer.content.length / 3)
      this.recordTimer = setTimeout(() => {
        this.answerStr = ''
        this.selectedOrder = -1
      }, 1200 * n + 3000) // 设置无触控的超时时间，这里是20秒
    },
    startTimer () {
      this.startTime = Date.now() - this.elapsedTime * 1000
      this.timer = setInterval(() => {
        this.elapsedTime = Math.floor((Date.now() - this.startTime) / 1000)
      }, 1000)
      this.isRunning = true
    },
    stopTimer () {
      clearInterval(this.timer)
      this.timer = null
      this.isRunning = false
    },

    stopSpeech () {
      if (this.audioElement) {
        this.audioElement.pause()
        this.audioElement.currentTime = 0
      }
    },
    resetSpeech (id) {
      this.stopSpeech()
      audioGet(id + '.mp3').then(res => {
        this.audioElement = new Audio()
        this.audioElement.src = URL.createObjectURL(res)
        console.log(this.audioElement)
        this.audioElement.play()
      })
    }
    // stopSpeech () {
    //   if (this.speech) {
    //     window.speechSynthesis.cancel()
    //     this.speech = null
    //   }
    // },
    // resetSpeech (text) {
    //   this.stopSpeech()
    //   const voices = window.speechSynthesis.getVoices()
    //   const childVoice = voices.find(voice => voice.name.includes('kang') && voice.lang.startsWith('zh'))
    //   this.speech = new SpeechSynthesisUtterance(text)
    //   this.speech.voice = childVoice
    //   window.speechSynthesis.speak(this.speech)
    // }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  height: 100vh;
}

.leftPart {
  width: 50%;
  padding-top: 20px;
}

.rightPart {
  width: 50%;
}

.picCard {
  margin: 2vh auto;
  position: relative;
}

.picWrapper {
  height: 46vh;
  width: 46vh;
  border-radius: 2em;
  overflow: hidden;
  box-shadow: #2f2f27 0 2px 5px;
  margin: 10px auto;
}

.picWrapper img {
  height: 100%;
  width: 100%;
}

.answerWrapper {
  position: absolute;
  width: 46vh;
  bottom: 0;
}

.answerCard {
  font-size: larger;
  background-color: rgba(228, 225, 213, 0.8);
  border-radius: 30px;
  padding: 1.5em;
}
p{
  font-weight: bold;
  color: #545536;
  margin-bottom: 0;
  margin-top: 0.4em;
}
.text-justify {
  text-align: justify;
  text-align-last: left;
}

.questionWrapper {
  width: 40vw;
  margin: 100px auto;
  padding: 20px;
  background-color: rgba(245, 245, 245, 0.2);
  box-shadow: #b9b8b2 5px 5px 10px;
}

.questionItem {
  font-size: large;
  line-height: 1em;
  background-color: #f0f0e8;
  width: 80%;
  margin: 2em auto;
  padding: 1em;
  text-align: left;
  border-radius: 10px;
  box-shadow: #b9b8b2 5px 5px 10px;
  cursor: pointer;
}

.questionItem:hover {
  background-color: white;
}

.selected {
  background-color: white;
}
</style>
