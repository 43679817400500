<template>
  <div class="container">
    <div class="mainPart">
<!--      <div class="aboutWrapper">-->
<!--        <div class="leftPart"></div>-->
<!--        <div class="centerPart" @click="goto('about')">-->
<!--          <img src="@/assets/welcome/aboutme.png" height="40" alt=""/>-->
<!--&lt;!&ndash;          <router-link to="/about" tag="p" class="aboutButton">关于我们</router-link>&ndash;&gt;-->
<!--          <p>关于我们</p>-->
<!--        </div>-->
<!--        <div class="rightPart">-->
<!--          <el-button round icon="el-icon-picture-outline" class="picButton" @click="goto('pictureWall')">-->
<!--            照片墙-->
<!--          </el-button>-->
<!--        </div>-->
<!--      </div>-->
        <div class="centerPart" @click="goto('about')">
          <img src="@/assets/welcome/aboutme.png" height="40" alt=""/>
<!--          <router-link to="/about" tag="p" class="aboutButton">关于我们</router-link>-->
          <p>关于我们</p>
        </div>
      <div class="picWrapper">
        <div v-for="(p,i) in pList" :key="i" class="playground">
          <img :src="require('@/assets/welcome/'+p)" class="picItem" alt="Playground Image"/>
        </div>
      </div>
      <p class="welcomeText">欢迎来到植物的世界</p>
      <div>
        <el-button round class="callButton" @click="goto('addressBook')"> 开始通话</el-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WelcomeView',
  data () {
    return {
      pList: ['01.jpg', '02.jpg', '03.jpg']
    }
  },
  methods: {
    goto (n) {
      this.$router.push({ name: n })
    }
  }
}
</script>
<style scoped>
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainPart{
  width: 100vw;
}

.aboutWrapper {
  display: flex;
  justify-content: space-between;
}

.centerPart {
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  color: #545536;
}

.centerPart,
.leftPart,
.rightPart {
  width: 200px;
}

.rightPart {
  margin-left: auto;
}

.leftPart {
  margin-right: auto;
}

.picButton {
  border-color: #545536 !important;
  color: #545536 !important;
  font-size: larger;
}

.picButton:hover {
  background-color: #545536 !important;
  color: #fff !important;
}

.picWrapper {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin: 2vh auto;
}

.picItem {
  width: 20vw;
  height: 46vh;
  background-color: #fafaf1;
  border-radius: 10vw;
}

.welcomeText {
  color: #545536;
  font-size: 2em;
  margin-bottom: 2vh;
  font-weight: bold;
  letter-spacing: 0.5em;
}

.callButton {
  background: #545536 !important;
  border-color: #545536 !important;
  color: #fff !important;
  width: 10em;
  height: 2em;
  font-size: 24px;
  letter-spacing: 0.5em;
}

.callButton:hover {
  background: #fff !important;
  border-color: #545536 !important;
  color: #545536 !important;
  opacity: 0.8;
}
</style>
