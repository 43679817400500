<template>
  <div class="container">
    <div class="backPart" @click="goBack()">
      <img src="@/assets/back.png" width="60"/>
    </div>
    <div class="leftPart">
      <img src="@/assets/about/01.jpg" width="360"/>
    </div>
    <div class="rightPart">
      <h1>给植物打电话</h1>
      <h2>作品说明</h2>
      <p>《给植物打电话》试图从不以人类为中心的视角，重新建构人们对近旁植物，进而对周边环境的认知。这个位于小绿洲的“电话亭”，采用空间装置、数字媒体、交互感知、应用软件相结合的方式，在疗愈式的参与过程中，引发人们对高密度城市、社区构成、地域特征、生命与环境等议题的思考。</p>
      <h2>参展人简介</h2>
      <p>窦平平，剑桥大学博士，南京大学建筑与城市规划学院副教授，LanD Studio主持建筑师。中国建筑学会环境行为学术委员会、江苏省土木建筑学会建筑创作专委会、江苏省美学协会设计专委会委员。国际期刊AD客座主编，剑桥中国学刊客座编委。主持欧盟伊拉斯谟国际合作教学计划、国家自然科学基金等。曾任伦敦RMJM事务所建筑师，谢菲尔德大学客座设计导师。      </h3></p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AboutView',
  methods: {
    goBack () {
      this.$router.push({ name: 'welcome' })
    }
  }
}
</script>
<style scoped>
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}
.rightPart, .backPart ,.leftPart{
  margin-top: auto;
  margin-bottom: auto;
}
.backPart {
  margin-left: 5vw;
  cursor: pointer;
}
.leftPart{
  overflow: hidden;
  min-width: 360px;
  margin-left: 1vw;
  border-top-left-radius: 50% 30%;
  border-top-right-radius: 50% 30%;
}
.rightPart{
  margin-right: 5vw;
  margin-left: 5vw;
  text-align: left;
  line-height: 1.5em;
  color: #545536;
}
.rightPart h1{
  margin-bottom: 2em;
  letter-spacing: 0.2em;
}
.rightPart h2{
  margin-top: 2em;
}
.rightPart p{
  text-align: justify;
  text-align-last: left;
}
</style>
